import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  setErrorMessage,
  setIsLoading,
  setIsToasterError,
  setIsToasterSuccess,
  setSuccessMessage,
} from "./app";
import API from "../../api/api";

export interface IObjectVerwaltung {
  adressart: string;
  objektid: number;
  objektId?: number;
  kassenzeichen: number;
  strassebez: string;
  hausnr?: number;
  hausnrzusatz: string | null;
  plz: string;
  bezirkbez: null | string;
  bezirknr: null | string | number;
  gemeindebez: string;
  gemeindenr: number;
  hausnrinfo: string;
  ortsteilbez: string;
  ortsteilnr: number;
  strassenr: number;
}

export interface AddressData {
  gemeinden: {
    id: string;
    gemeindeBezeichnung: string;
    gemeindePostleitzahl: number;
    gemeindeNummer: number;
  }[];
  streets: {
    id: string;
    gemeindeNummer: number;
    strasseBezeichnung: string;
    strasseNummer: number;
    ortsteilNummer: number;
  }[];
}

interface IOobjectVerwaltung {
  client: {
    data: IObjectVerwaltung[];
    isDataLoading: boolean;
    addressData: AddressData;
    errorMessage: string;
  };
}

const initialState: IOobjectVerwaltung = {
  client: {
    data: [],
    isDataLoading: true,
    addressData: { gemeinden: [], streets: [] },
    errorMessage: "",
  },
};

export const getObjectVerwaltungData = createAsyncThunk(
  "objectVerwaltung/client/getObjectVerwaltungData",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      const data = await API.objectVerwaltung.get.getObjectVerwaltungData();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const getObjectVerwaltungAddressData = createAsyncThunk(
  "objectVerwaltung/client/getObjectVerwaltungAddressData",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      const data =
        await API.objectVerwaltung.get.getObjectVerwaltungAddressData();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const addNewObject = createAsyncThunk(
  "objectVerwaltung/client/addNewObject",
  async (
    {
      homeNumber,
      objectNumber,
      place,
      postCode,
      street,
      homeNumberTo,
      ortsteilNr,
      hausNrZusatz,
      hausNrZusatzTo,
    }: {
      homeNumber: string;
      objectNumber: string;
      place: string;
      postCode: number;
      street: string;
      homeNumberTo?: string;
      ortsteilNr: string | number;
      hausNrZusatz?: string;
      hausNrZusatzTo?: string;
      setAccepted: () => void;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));

      const data = await API.objectVerwaltung.post.addNewObject({
        homeNumber,
        objectNumber,
        place,
        postCode,
        street,
        homeNumberTo,
        ortsteilNr,
        hausNrZusatz,
        hausNrZusatzTo,
      });

      return data;
    } catch (error: any) {
      // dispatch(setIsToasterError(true));
      // dispatch(setErrorMessage(error?.response?.data?.message));
      dispatch(setObjectVerwaltungErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
export const validateBookingCode = createAsyncThunk(
  "objectVerwaltung/client/validateBookingCode",
  async (
    {
      bookingCode,
    }: {
      bookingCode: string;
      closeModalHandler: () => void;
    },
    { dispatch }
  ) => {
    try {
      const data = await API.objectVerwaltung.post.validateBookingCode({
        bookingCode,
      });

      dispatch(setIsToasterSuccess(true));
      dispatch(setSuccessMessage("Test"));

      return data;
    } catch (error: any) {
      // dispatch(setIsToasterError(true));
      // dispatch(setErrorMessage(error?.response?.data?.message));
      dispatch(setObjectVerwaltungErrorMessage(error?.response?.data?.message));
    }
  }
);

const objectVerwaltungSlice = createSlice({
  name: "objectVerwaltung",
  initialState,
  reducers: {
    clearObjectVerwaltungData: (state) => {
      state.client.data = [];
      state.client.isDataLoading = false;
    },
    clearObjectVerwaltungAddressData: (state) => {
      state.client.addressData = initialState.client.addressData;
      state.client.isDataLoading = false;
      state.client.errorMessage = "";
    },
    setObjectVerwaltungErrorMessage: (state, action: PayloadAction<string>) => {
      state.client.errorMessage = action.payload;
    },
    clearObjectVerwaltungErrorMessage: (state) => {
      state.client.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getObjectVerwaltungData.pending, (state) => {
      state.client.isDataLoading = true;
    });
    builder.addCase(getObjectVerwaltungData.fulfilled, (state, action) => {
      state.client.isDataLoading = false;
      if (action.payload.data && action.payload.data.length) {
        state.client.data = action.payload.data;
      } else if (action.payload) {
        state.client.data = action.payload;
      }
    });
    builder.addCase(getObjectVerwaltungData.rejected, (state) => {
      state.client.isDataLoading = false;
    });
    builder.addCase(getObjectVerwaltungAddressData.pending, (state) => {
      state.client.isDataLoading = true;
    });
    builder.addCase(
      getObjectVerwaltungAddressData.fulfilled,
      (state, action) => {
        state.client.isDataLoading = false;
        if (action.payload) {
          state.client.addressData = action.payload;
        }
      }
    );
    builder.addCase(getObjectVerwaltungAddressData.rejected, (state) => {
      state.client.isDataLoading = false;
    });
    builder.addCase(addNewObject.pending, (state) => {
      state.client.isDataLoading = true;
    });
    builder.addCase(addNewObject.fulfilled, (state, action) => {
      state.client.isDataLoading = false;
      if (action.payload) {
        action.meta.arg.setAccepted();
      }
    });
    builder.addCase(addNewObject.rejected, (state) => {
      state.client.isDataLoading = false;
    });
    builder.addCase(validateBookingCode.pending, (state) => {
      state.client.isDataLoading = true;
    });
    builder.addCase(validateBookingCode.fulfilled, (state, action) => {
      state.client.isDataLoading = false;
      if (action.payload) {
        if (action.payload.data && action.payload.data.length) {
          state.client.data = action.payload.data;
        }
        action.meta.arg.closeModalHandler();
      }
    });
    builder.addCase(validateBookingCode.rejected, (state) => {
      state.client.isDataLoading = false;
    });
  },
});

export const {
  clearObjectVerwaltungAddressData,
  clearObjectVerwaltungData,
  clearObjectVerwaltungErrorMessage,
  setObjectVerwaltungErrorMessage,
} = objectVerwaltungSlice.actions;

export const objectVerwaltungReducer = objectVerwaltungSlice.reducer;
